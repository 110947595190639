<div class="dialog-wrap {{inputMode}}">
    <nb-card class="small-header smart-table no-border">
        <nb-card-header>
            <ngx-card-header [size]="size" [icon]="favicon" [title]="tabTitle + ' = ' + (summaryAmount >= 0 ? (summaryAmount | currency:'VND') : ('('+(-summaryAmount | currency:'VND')+')'))" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>

        <nb-card-body>
            <ag-grid-angular #agGrid style="width: 100%;" [ngStyle]="{'height': gridHeight}" id="myGrid" class="{{themeName}}"
                [modules]="modules" 
                [columnDefs]="columnDefs" 
                [debug]="true" 
                [enableCellTextSelection]="enableCellTextSelection"
                [ensureDomOrder]="ensureDomOrder"
                [rowMultiSelectWithClick]="rowMultiSelectWithClick" 
                [suppressRowClickSelection]="suppressRowClickSelection"
                [defaultColDef]="defaultColDef" 
                [rowSelection]="rowSelection"
                [rowModelType]="rowModelType" 
                [paginationPageSize]="paginationPageSize" 
                [cacheOverflowSize]="cacheOverflowSize"
                [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
                [infiniteInitialRowCount]="infiniteInitialRowCount" 
                [maxBlocksInCache]="maxBlocksInCache" 
                [cacheBlockSize]="cacheBlockSize"
                [getRowNodeId]="getRowNodeId" 
                [components]="components" 
                [rowData]="rowData" 
                (gridReady)="onGridReady($event)" 
                (selectionChanged)="onSelectionChanged($event)"
                [multiSortKey]="multiSortKey" 
                [rowDragManaged]="rowDragManaged" 
                [getRowHeight]="getRowHeight" 
                [rowHeight]="rowHeight"
                (columnResized)="onColumnResized()" 
                (rowSelected)="onRowSelected()" 
                [pagination]="pagination">
            </ag-grid-angular>
        </nb-card-body>
    </nb-card>
</div>